// extracted by mini-css-extract-plugin
export var active = "primaryButton-module--active--bebee";
export var buttonContent = "primaryButton-module--buttonContent--4463d";
export var dark = "primaryButton-module--dark--fede6";
export var disabled = "primaryButton-module--disabled--ce14f";
export var ghost = "primaryButton-module--ghost--7289a";
export var iconRight = "primaryButton-module--iconRight--7791f";
export var inline = "primaryButton-module--inline--4313b";
export var outline = "primaryButton-module--outline--38d50";
export var primaryButton = "primaryButton-module--primaryButton--1dfd0";
export var ripple = "primaryButton-module--ripple--9604c";
export var rippleActive = "primaryButton-module--ripple-active--db545";
export var rippleInactive = "primaryButton-module--ripple-inactive--85acc";
export var rounded = "primaryButton-module--rounded--8dc00";