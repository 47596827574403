import React, { forwardRef, useState } from 'react';
import propTypes from 'prop-types';
import * as styles from './primaryButton.module.css';
import classnames from 'classnames';
import { Link } from 'gatsby-plugin-intl';

import ArrowRightDarkBg from '../icons/arrow_right_dark_bg.svg';
import ArrowRightLightBg from '../icons/arrow_right_light_bg.svg';

const PrimaryButton = forwardRef((props, ref) => {
  const [coords, setCoords] = useState({ x: 0, y: 0 });
  const [rippleActive, setRippleActive] = useState(false);

  const {
    className,
    ripple,
    children,
    onMouseDown,
    dark,
    ghost,
    outline,
    iconRight,
    href,
    to,
    inline,
    withArrowIcon,
    noIcon,
    disabled,
    ...restProps
  } = props;

  const activateRipple = e => {
    const rect = e.target.getBoundingClientRect();
    setCoords({ x: e.clientX - rect.left - 10, y: e.clientY - rect.top - 10 });
    setRippleActive(true);
    onMouseDown && onMouseDown(e);
  };

  let Element = 'button';
  if (to) {
    Element = Link;
  }

  if (href) {
    Element = 'a';
  }

  return (
    <Element
      {...restProps}
      className={classnames({
        primaryButton: true,
        [styles.primaryButton]: true,
        [styles.rounded]: true,
        [styles.dark]: dark,
        [styles.ghost]: ghost || outline,
        [styles.inline]: inline,
        [styles.disabled]: disabled,
        [styles.outline]: outline,
        [className]: className,
      })}
      to={to}
      href={href}
      onMouseDown={activateRipple}
      onMouseUpCapture={() => setRippleActive(false)}
      disabled={disabled}
      ref={ref}
    >
      {ripple && (
        <div
          className={[styles.ripple, rippleActive ? styles.active : ''].join(
            ' ',
          )}
          style={{
            left: coords.x,
            top: coords.y,
          }}
        />
      )}
      <span className={styles.buttonContent}>{children}</span>
      {(to || withArrowIcon) && !noIcon && (
        <div className={styles.iconRight}>
          {!dark ? <ArrowRightDarkBg /> : <ArrowRightLightBg />}
        </div>
      )}
      {iconRight && <div className={styles.iconRight}>{iconRight}</div>}
    </Element>
  );
});

PrimaryButton.displayName = 'PrimaryButton';

PrimaryButton.propTypes = {
  className: propTypes.any,
  ripple: propTypes.bool,
  children: propTypes.oneOfType([
    propTypes.node.isRequired,
    propTypes.arrayOf(propTypes.node),
  ]),
  onMouseDown: propTypes.func,
  onMouseUp: propTypes.func,
  dark: propTypes.bool,
  iconRight: propTypes.object,
  ghost: propTypes.bool,
  outline: propTypes.bool,
  href: propTypes.string,
  to: propTypes.string,
  inline: propTypes.bool,
  withArrowIcon: propTypes.bool,
  noIcon: propTypes.bool,
  disabled: propTypes.bool,
};

export default PrimaryButton;
