import React, { Ref, Suspense, forwardRef, lazy, useCallback, useRef } from 'react';

import SEO from '@components/seo';

import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import TextHero from '@components/TextHero';
import { Box, CircularProgress, List, ListItem, ListItemText, Paper, Typography } from '@mui/material';
import Confetti from 'react-confetti';
import { saveAs } from 'file-saver';
import { useScreenshot } from 'use-react-screenshot';

import PrimaryButton from '@src/components/primaryButton';
import Logo from '@src/components/Logo';
import styled from '@emotion/styled';

const WellnessPersona = lazy(() => import(`../icons/recap/personas/wellness.svg`));
const FoodPersona = lazy(() => import(`../icons/recap/personas/food.svg`));
const ShoppingPersona = lazy(() => import(`../icons/recap/personas/shopping.svg`));
const HomePersona = lazy(() => import(`../icons/recap/personas/home.svg`));
const EntertainmentPersona = lazy(() => import(`../icons/recap/personas/entertainment.svg`));
const BellowAveragePersona = lazy(() => import(`../icons/recap/personas/bellow-average.svg`));
const TransportPersona = lazy(() => import(`../icons/recap/personas/transport.svg`));

const FoodCategory = lazy(() => import(`../icons/recap/categories/food.svg`));
const WellnessCategory = lazy(() => import(`../icons/recap/categories/wellness.svg`));
const ShoppingCategory = lazy(() => import(`../icons/recap/categories/shopping.svg`));
const EntertainmentCategory = lazy(() => import(`../icons/recap/categories/entertainment.svg`));
const TransportCategory = lazy(() => import(`../icons/recap/categories/transport.svg`));
const OtherCategory = lazy(() => import(`../icons/recap/categories/other.svg`));
const HouseCategory = lazy(() => import(`../icons/recap/categories/house.svg`));

const ButtonWrapper = styled(Grid)({
  width: 342,
  display: 'flex',
  justifyContent: 'center',
  paddingBottom: 32,
  paddingTop: 32,

  "@media (max-width: 450px)": {
    backgroundColor: '#F3EBFE',
    width: "100vw",
  },
});

const StyledButton = styled(PrimaryButton)({
  width: '100%',
  "@media (max-width: 450px)": {
    width: "90% !important",
  },
});

const StyledPaper = styled(Paper)({
  width: 342,
  height: 608,
  backgroundColor: '#F3EBFE',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',

  "@media (max-width: 450px)": {
    width: "100vw",
    height: "100%",
  },
});

const StyledContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  height: '100vh',
  "@media (max-width: 450px)": {
    backgroundColor: '#F3EBFE'
  },
});

const dataURLtoFile = (dataurl) => {
  if (!dataurl) {
    return;
  }
  var arr = dataurl.split(","),
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], "Rocker-2023-Recap.webp", { type: 'image/webp' });
};

type RecapType = {
  p: string, // Persona
  tm: [string], // Top merchants
  tc: { // Top category
    t: string;
    mc: string, // Most common
    l: string, // Largest purchase
  }
}

const RecapContent = forwardRef(({ content }: { content: RecapType }, ref: Ref<HTMLDivElement>) => {

  const getPersona = useCallback((persona: string): { icon: JSX.Element, title: string, description: string } => {
    switch (Number(persona)) {
      case 1: // Self care-ikonen
        return {
          icon: <WellnessPersona />,
          title: 'Self care-ikonen',
          description: 'Det lyser om dig! Under 2023 spenderade du mer på hälsa och skönhet jämfört med andra Rockeranvändare.',
        };
      case 2: // Smakfantasten
        return {
          icon: <FoodPersona />,
          title: 'Smakfantasten',
          description: 'Mums! Under 2023 spenderade du mer på Mat och Dryck jämfört med andra Rockeranvändare.',
        };
      case 3: // Shoppinggurun
        return {
          icon: <ShoppingPersona />,
          title: 'Shoppinggurun',
          description: 'Treat yourself! Under 2023 spenderade du mer på Shopping jämfört med andra Rockeranvändare.',
        };
      case 4: // Hemmafixaren
        return {
          icon: <HomePersona />,
          title: 'Hemmafixaren',
          description: 'Borta bra men hemma bäst! Under 2023 spenderade du mer på Hem och Trädgård jämfört med andra Rockeranvändare.',
        };
      case 5: // Livsnjutaren
        return {
          icon: <EntertainmentPersona />,
          title: 'Livsnjutaren',
          description: 'Det är viktigt att ha kul! Under 2023 spenderade du mer på Fritid jämfört med andra Rockeranvändare.',
        };
      case 6: // Globetrottern
        return {
          icon: <TransportPersona />,
          title: 'Globetrottern',
          description: 'Bil, buss, tåg eller flyg? Under 2023 spenderade du mer på Transport jämfört med andra Rockeranvändare.',
        };
      case 7: // Den medvetna köparen
        return {
          icon: <BellowAveragePersona />,
          title: 'Den medvetna köparen',
          description: 'Tänk först, handla sen. Under 2023 spenderade du mindre i alla kategorier jämfört med andra Rockeranvändare.',
        };
    }
  }, []);

  const getCategory = useCallback((persona: string): { icon: JSX.Element, title: string } => {
    switch (Number(persona)) {
      case 1: // Hälsa & Skönhet
        return {
          icon: <WellnessCategory />,
          title: 'Hälsa & Skönhet',
        };
      case 2: // Mat & Dryck
        return {
          icon: <FoodCategory />,
          title: 'Mat & Dryck',
        }
      case 3: // Shopping
        return {
          icon: <ShoppingCategory />,
          title: 'Shopping',
        }
      case 4: // Hem & Trädgård
        return {
          icon: <HouseCategory />,
          title: 'Hem & Trädgård',
        }
      case 5: // Fritid
        return {
          icon: <EntertainmentCategory />,
          title: 'Fritid',
        }
      case 6: // Transport
        return {
          icon: <TransportCategory />,
          title: 'Transport',
        }
      default:
        return {
          icon: <OtherCategory />,
          title: 'Övrigt',
        };
    }
  }, []);

  return (
    <StyledPaper id='recap-content' ref={ref} elevation={3}>
      <Box display="flex" flexDirection="column" alignItems="center" paddingTop={12} paddingX={5}>
        <Logo height={18} />
        <Typography variant="h5" component="h1" color="#551DA8">
          recap
        </Typography>
        <Avatar
          alt="Avatar"
          sx={{ width: 116, height: 116, marginTop: 6, marginBottom: 2, backgroundColor: 'transparent' }}
        >
          <Suspense fallback={<div>Loading...</div>}>
            {getPersona(content.p).icon}
          </Suspense>
        </Avatar>
        <Typography variant="h1" component="h2" gutterBottom>
          {getPersona(content.p).title}
        </Typography>
        <Typography textAlign="center">
          {getPersona(content.p).description}
        </Typography>
        <Box display="flex" justifyContent="space-between" width="100%" alignSelf="center" margin="auto" marginTop={8}>
          <Box maxWidth="50%">
            <Typography variant="h6" component="h3" fontSize={18}>
              Toppbutiker
            </Typography>
            <List>
              {content.tm.map((merchant, index) => (
                <ListItem key={index} sx={{ paddingLeft: 0 }}>
                  {/* <Avatar sx={{ width: 24, height: 24, marginRight: 2 }} src={merchant.imgUrl} /> */}
                  <ListItemText primary={`${index + 1}. ${merchant}`} />
                </ListItem>
              ))}
            </List>
          </Box>
          <Box maxWidth="50%">
            <Typography variant="h6" component="h3" fontSize={18}>
              Toppkategori
            </Typography>
            <List>
              <ListItem sx={{ paddingLeft: 0, paddingBottom: 0, paddingRight: 0 }}>
                <Avatar
                  sx={{ width: 24, height: 24, marginRight: 2, backgroundColor: 'transparent' }}
                >
                  <Suspense fallback={<div>Loading...</div>}>
                    {getCategory(content.tc.t).icon}
                  </Suspense>
                </Avatar>
                <ListItemText primary={getCategory(content.tc.t).title} sx={{ fontWeight: "700" }} />
              </ListItem>
              <ListItem sx={{ paddingLeft: 0, paddingBottom: 0, paddingTop: 0, paddingRight: 0 }}>
                {/* <Avatar sx={{ width: 24, height: 24, marginRight: 2 }} src={content.topCategory.mostCommon.imgUrl} /> */}
                <ListItemText primary="Vanligaste köp" secondary={`${content.tc.mc}`} primaryTypographyProps={{ fontWeight: '700', color: '#736E7A', fontSize: 10, sx: { textTransform: 'uppercase' } }} secondaryTypographyProps={{ variant: 'body1', color: 'black' }} />
              </ListItem>
              <ListItem sx={{ paddingLeft: 0, paddingBottom: 0, paddingTop: 0, paddingRight: 0 }}>
                {/* <Avatar sx={{ width: 24, height: 24, marginRight: 2 }} src={content.topCategory.largest.imgUrl} /> */}
                <ListItemText primary="Största köp" secondary={`${content.tc.l}`} primaryTypographyProps={{ fontWeight: '700', color: '#736E7A', fontSize: 10, sx: { textTransform: 'uppercase' } }} secondaryTypographyProps={{ variant: 'body1', color: 'black' }} />
              </ListItem>
            </List>
          </Box>
        </Box>
      </Box>
      <Box display="flex" justifyContent="space-between" alignItems="baseline" width="100%" paddingBottom={6} paddingX={5}>
        <Logo height={18} width={100} />
        <Typography variant='body1'>
          www.rocker.com
        </Typography>
      </Box>
    </StyledPaper>
  );
});

RecapContent.displayName = 'RecapContent';

const doc = typeof document === 'undefined' ? undefined : document;

const Recap = ({ location }) => {
  const urlSearchParams = new URLSearchParams(location.search);
  const content = urlSearchParams.get('c');

  if (content === null) {
    const windowLocation = typeof window !== 'undefined' ? window.location : null;
    windowLocation?.replace('/404');

    return null;
  }

  const decodedContent = decodeURIComponent(content);
  const parsedContent: RecapType = JSON.parse(decodedContent);

  const ref = useRef(null);
  const [screenshotImage, takeScreenshot] = useScreenshot();
  const [isLoading, setIsLoading] = React.useState(false);

  const handleShare = useCallback(async (image: any) => {
    const data = {
      files: [
        new File([dataURLtoFile(image)], 'Rocker-2023-Recap.webp', {
          type: 'image/webp',
        }),
      ],
      title: 'Rocker 2023 Recap',
    };

    try {
      if (navigator == undefined || !navigator.canShare(data)) {
        saveAs(image, 'Rocker-2023-Recap.webp');
        return;
      }
      try {
        await navigator.share(data);
      } catch {
        // Fallback for when the user cancels the share
      }
   } catch {
      saveAs(image, 'Rocker-2023-Recap.webp');
   }
}, []);

  const shareImage = useCallback((image: string) => {
    setIsLoading(false);
    handleShare(image);
  }, [handleShare]);

  const onShare = useCallback(async () => {
    setIsLoading(true);
    if (doc === undefined) {
      return;
    }

    if (!screenshotImage) {
      // This is an ugly hack to make sure the image is rendered before we share it
      await takeScreenshot(ref.current).then(async () => {
        setTimeout(async () => {
          await takeScreenshot(ref.current).then((image) => {
            shareImage(image);
          });
        }, 1000);
      })

    } else {
      shareImage(screenshotImage);
    }
  }, [shareImage, screenshotImage]);

  return (
    <div style={{ display: 'flex', width: '100vw', height: '100vh', justifyContent: 'center', alignContent: 'center', margin: 'auto' }}>
      <SEO title={"Dela ditt år hos Rocker"} />
      <Confetti
        colors={['#f44336', '#e91e63', '#9c27b0', '#673ab7', '#F25A9A', '#2196f3', '#03a9f4', '#00bcd4', '#B8B82E', '#4CAF50', '#8BC34A', '#CDDC39', '#FFEB3B', '#FFC107', '#FF9800', '#FF5722', '#F0B80F']}
        recycle={false}
        tweenDuration={6000}
      />
      <StyledContainer>
        <Grid container display="flex" justifyContent="center">
          <Grid md={6} sx={{ display: { xs: 'none', sm: 'block' } }}>
            <TextHero
              leftAligned
              size="medium"
              title={"Dela ditt år hos Rocker"}
              style={{ paddingTop: 32 }}
            />
          </Grid>
          <Grid md={6} xs={12}>
            <Grid>
              <RecapContent ref={ref} content={parsedContent} />
            </Grid>
            <ButtonWrapper>
              <StyledButton
                onClick={onShare}>
                  {isLoading ? <CircularProgress color="secondary" /> : 'Dela'}
              </StyledButton>
            </ButtonWrapper>
          </Grid>
        </Grid>
      </StyledContainer>
    </div>
  );
};

export default Recap;

