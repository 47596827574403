/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import { Helmet, HelmetProps } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

type SEOProps = HelmetProps & {
  lang?: string;
  url?: string;
  title?: string;
  description?: string;
  imageUrl?: string;
  noIndex?: boolean;
};

function SEO({
  url,
  title,
  description,
  imageUrl,
  noIndex = false,
  ...rest
}: SEOProps) {
  const { site } = useStaticQuery(
    graphql`
      query SEOQuery {
        site {
          siteMetadata {
            title
            iTunesApp
            siteUrl
            author
            metaDescription
            author
            image
          }
        }
      }
    `,
  );

  let _title: string = title || site.siteMetadata.title;
  if (!_title.startsWith('Rocker')) {
    _title = `Rocker - ${_title}`;
  }

  const seo = {
    title: _title,
    description: description || site.siteMetadata.metaDescription,
    image: `${site.siteMetadata.siteUrl}${site.siteMetadata.image}`,
    url: url || `${site.siteMetadata.siteUrl}${'/'}`,
  };
  return (
    <Helmet title={seo.title} {...rest}>
      <meta property="og:title" content={seo.title} />
      <meta property="og:url" content={seo.url} />
      <meta property="description" content={seo.description} />
      <meta property="og:description" content={seo.description} />
      {imageUrl && <meta property="og:image" content={imageUrl} />}
      {noIndex && <meta name="robots" content="noindex" />}
    </Helmet>
  );
}

export default SEO;
